import React from "react";
import SEO from "../components/seo";
import { useStaticQuery, graphql } from "gatsby";
import MyNavbar from "../views/navbar";
import Footer from "../views//footer";
import About from "../views/sections/about";
import Contact from "../views/sections/contact";

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
          title
          keywords
          description
        }
      }
    }
  `);

  const {
    site: {
      siteMetadata: { keywords, description }
    }
  } = data;

  const frontmatter = {}

  return (
    <div>
      <SEO lang="en" title="VA Software Solutions" keywords={keywords} description={description} />
      <div className="main-wrapper">
      <MyNavbar />
      <About frontmatter={frontmatter}/>
      <Contact />
      <Footer />
    </div>
    </div>
  );
};

export default AboutPage;
